import React, { useEffect } from 'react'

const useElementOverflow = (ref, callback: () => any) => {
  useEffect(() => {
    if ( ref.current.offsetHeight < ref.current.scrollHeight ||
      ref.current.offsetWidth < ref.current.scrollWidth) {
        callback()
      }
  }, [ref])
}

export default useElementOverflow