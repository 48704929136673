import React, { useState, useEffect, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import useElementOverflow from '../customHooks/useElementOverflow'

import { FaAngleDown } from '@react-icons/all-files/fa/FaAngleDown'
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt'

import { PageName } from '../constants'

const ResourceCard = ({ item }) => {
  const paragraphRef = useRef(null)
  const [isLineClamped, setIsLineClamped] = useState(true)
  const [hasShowMoreButton, setHasShowMoreButton] = useState(false)

  useElementOverflow(paragraphRef, () => {
    if (isLineClamped) {
      setHasShowMoreButton(true)
    }
  })

  const lineClampStyle = isLineClamped ? 'line-clamp-3' : ''
  const iconStyle = isLineClamped ? '' : 'transform -rotate-180'

  return (
    <div className="p-4 shadow bg-white rounded-md">
      <div className="flex items-center justify-between">
        <a
          className="text-lg font-medium text-beaver-green hover:underline hover:text-blue-600 visited:text-blue-600 transition-colors"
          href={item.href}
        >
          <div className="flex items-center gap-2">
            <p>{item.title}</p>
            <FaExternalLinkAlt />
          </div>
        </a>
        {/* {item.blogLinks && (
          <Link
            className="px-3 py-1 bg-blue-500 rounded-md text-white text-base font-normal"
            to={item.blogLinks[0].href}
          >
            {item.blogLinks[0].title || 'อ่านเพิ่ม'}
          </Link>
        )} */}
      </div>
      <p ref={paragraphRef} className={`mt-4 ${lineClampStyle}`}>
        {item.description}
      </p>
      {hasShowMoreButton && (
        <button
          onClick={() => setIsLineClamped((previous) => !previous)}
          className="text-sm mt-4 text-beaver-green flex items-center gap-2"
        >
          <FaAngleDown
            className={`transition-transform duration-300 ${iconStyle}`}
          />
          {isLineClamped ? 'More' : 'Less'}
        </button>
      )}
    </div>
  )
}

export const ResourcePageTemplate = ({ groups }) => {
  return (
    <>
      <section>
        <div className="container-xl section-py-normal">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-medium">
              {PageName.RESOURCE}
            </h1>

            <p className="my-8 max-w-lg sm:my-12 text-xl mx-auto">
              ตามเก็บเรื่องที่ไม่เข้าใจ ศึกษาเรื่องที่สนใจเพิ่มเติม รวบรวม Tools
              ที่จะได้ใช้บ่อยๆไว้พร้อม
            </p>
          </div>
        </div>
      </section>
      {groups.map((group, index) => {
        const bgColor = index % 2 === 1 ? '' : 'bg-gray-100'
        return (
          <section className={`${bgColor}`}>
            <div className="container-xl section-py-normal">
              <h2 className="text-2xl font-medium">{group.title}</h2>
              <p className="mt-2">{group.description}</p>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
                {group.items.map((item) => {
                  return <ResourceCard key={item.title} item={item} />
                })}
              </div>
            </div>
          </section>
        )
      })}
    </>
  )
}

const ResourcePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ResourcePageTemplate groups={frontmatter.groups} />
    </Layout>
  )
}

export const resourcePageQuery = graphql`
  query resourcePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        groups {
          title
          description
          items {
            title
            description
            href
            # blogLinks {
            #   title
            #   href
            # }
          }
        }
      }
    }
  }
`

export default ResourcePage
